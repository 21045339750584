// // TradingViewWidget.jsx

import React, { useEffect, useRef, useState } from 'react';

let tvScriptLoadingPromise;

export default function TradingViewWidget(props) {
  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      // console.log(props, 'imperial')
      //  let  data1 = props?.selec?.split("-")
      //  console.log("data123",data1)
      //   let data = data1.join("")

      // let data = props?.selec?.data?.instId
      let data = props?.selec
      // let data = data1.join("")
      // console.log(typeof data, 'data');
      let dpair = "Binance:" + data

      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_5911e') && 'TradingView' in window) {

          new window.TradingView.widget({
            width: "100%",
            height: 200,
            symbol: dpair,
            interval: "D",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            withdateranges: true,
            hide_side_toolbar: false,
            hide_legend: true,
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_5911e",

          });
        }
      }

    },
    [props.selec]
  );



  return (
    <div className='tradingview-widget-container'>
      <div id='tradingview_5911e' />
    </div>
  );
}