import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import Axios from "../../Axios";
import { Slider } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { Stack } from "rsuite";


const ThumbSlider = styled(Slider)(({ theme }) => ({
  marginTop: 10,
  '& .MuiSlider-thumb': {
    color: 'black',
    transform: 'rotate(45deg)',
    width: 12,
    height: 12,
    marginTop: '-8px',
    border: '3px solid #b7bdc6',
    borderRadius: '2px',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
      transform: 'rotate(-45deg)',
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: '#474d57'
  },
  '& .MuiSlider-root': {
    color: '#b7bdc6'
  },
  '& .MuiSlider-track': {
    color: '#b7bdc6'
  },
  '& .MuiSlider-valueLabel': {
    top: '-30px',
    transform: 'rotate(-45deg)',
    backgroundColor: 'transparent',
  },
  '& .MuiSlider-valueLabel.MuiSlider-valueLabelOpen': {
    transform: 'rotate(-45deg)',
  },
  '& .MuiSlider-mark': {
    // backgroundColor:'black',
    color: 'black',
    transform: 'rotate(45deg)',
    width: 8,
    height: 8,
    marginTop: '-4px',
    border: '2px solid #474d57',
    '&:hover': {
      backgroundColor: '#474d57',
      border: '2px solid black',
    },
    '& $active': {
      backgroundColor: '#474d57',
      border: '2px solid black',
    },
    '&:focus': {
      border: '2px solid black',
    },
    '&:active': {
      border: '2px solid black',
    }
  }
}));

const ValueLabelComponent = (props) => {
  const { value } = props;
  return (
    <div>
      <span>{value}%</span>

    </div>
  );
};




const SellForminnermarket = ({ selected, pair, index, ordertype, labe, market }) => {
  const [value, setValue] = React.useState()
  const user = JSON.parse(window.localStorage.getItem("users"))
  const [price, setPrice] = React.useState();
  const [Amount, setAmount] = React.useState();
  const [total, settotal] = React.useState();
  const [sli, setSli] = React.useState(0)
  const Amountref = useRef(0)
  const [takeProfit, setTakeProfit] = React.useState('');
  const [isCheckedTakeProfit, setCheckedTakeProfit] = useState(false);
  const [isCheckedStopLoss, setCheckedStopLoss] = useState(false);
  const [balance, setBalance] = useState()
  const [activeClass, setActiveClass] = useState("1");

  const percentageValue = async (e) => {
    setActiveClass(e.target.id);
    console.log(e.target.value, "value");
  }


  const handleChangeTakeProfit = () => {
    setCheckedTakeProfit(!isCheckedTakeProfit);
  };
  const handleChangeStopLoss = () => {
    setCheckedStopLoss(!isCheckedStopLoss);
  };
  const handleTakeProfit = (event) => {
    setTakeProfit(event.target.value);
  };
  // const total = useRef()
  React.useEffect(() => {
    console.log("sell", selected, pair)


    if (selected) {
      setPrice(selected.price);
      // setAmount(selected.amount);
      // settotal(selected.total);
    }
    if (Amount !== "") {
      console.log(Amount)
      settotal(Amount * price)
    } else {
      settotal("")
    }
  }, []);

  React.useEffect(() => {
    console.log("sell", selected, pair)


    if (selected) {
      setPrice(selected.price);
      // setAmount(selected.amount);
      // settotal(selected.total);
    }
    if (Amount !== "" && Amountref?.current?.value !== '') {
      console.log(Amountref?.current?.value)
      settotal(Amountref?.current?.value * selected?.price)
    } else {
      settotal("")
    }
  }, [selected]);

  useEffect(() => {
    if (Amount !== "" && Amountref?.current?.value !== '') {
      settotal(Amountref?.current?.value * selected?.price)
    } else {
      settotal("")
    }
  }, [Amount])
  const handleonChange = (e) => {
    setValue(e.target.value)
    setSli(e.target.value)
  }

  const priceupdate = (event) => {
    const newValue = Math.max(0, Number(event.target.value))
    setPrice(newValue)
  };
  const Amountupdate = (event) => {
    const newValue = Math.max(0, Number(event.target.value))
    setAmount(newValue)
  };
  const totalupdate = (event) => {
    const newValue = Math.max(0, Number(event.target.value))
    settotal(newValue)
  };

  useEffect(() => {
    if (pair !== "") {
      setPrice("")
      setAmount("")
      settotal("")
    }
  }, [pair])


  const [load, setload] = useState(true)


  const buytrade = async () => {
    // alert("else")
    // alert(labe)
    try {
      // const pa = pair.split('-')[2]
      const pa = pair.split(-4);
      if (pa !== undefined) {
        if (labe === "open-short") {
          if (price === "") {
            toast.error("Please Fill the Price", {

              setPairduration: 2500,
              position: "top-center",

              // Styling
              style: {
                padding: "1rem",
                fontSize: "15px",
                color: "red",
                fontWeight: "bold",
              },
              className: "",

              // Custom Icon
              icon: "",

              // Aria
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          }
          else if (Amount === "" || Amount == '0') {
            toast.error("Please Fill the Amount", {

              duration: 1500,
              position: "top-center",

              // Styling
              style: {
                padding: "1rem",
                fontSize: "15px",
                color: "red",
                fontWeight: "bold",
              },
              className: "",

              // Custom Icon
              icon: "",

              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },

              // Aria
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          } else {
            // const usdtBalance = parseFloat(balance?.find(item => item.symbol === "USDT")?.balance || 0);
            const usdtBalance = balance;


            setload(false)
            if (user.trader_type === "user") {
              const pair12 = pair.split('-')[1]
              var a = ["future-", labe]
              var b = a.join("")
              const da = {
                instId: pair,
                tdMode: index,
                ccy: pair12,
                tag: "mk1",
                side: "sell",
                orderType: ordertype,
                sz: Amount,
                px: "0",
                trade_at: b,
                lever: value
              }
              const { data } = await Axios.post(`/trade/userTrade`, da, {
                headers: {
                  Authorization: localStorage.getItem("Mellifluous"),
                }
              })
              if (data) {
                setload(true)
                toast.success(data.message, {

                  duration: 4000,
                  position: "top-center",

                  // Styling
                  style: {
                    padding: "1rem",
                    fontSize: "15px",
                    color: "green",
                    fontWeight: "bold",
                  },
                  className: "",

                  // Custom Icon
                  icon: "👏",

                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },

                  // Aria
                  ariaProps: {
                    role: "status",
                    "aria-live": "polite",
                  },
                });
                setAmount("")
              } else {
                setload(true)
                toast.success("Something Went Wrong", {

                  duration: 4000,
                  position: "top-center",

                  // Styling
                  style: {
                    padding: "1rem",
                    fontSize: "15px",
                    color: "green",
                    fontWeight: "bold",
                  },
                  className: "",

                  // Custom Icon
                  icon: "👏",

                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },

                  // Aria
                  ariaProps: {
                    role: "status",
                    "aria-live": "polite",
                  },
                });
              }
            } else {

              const pair12 = pair.split('-')[1]
              let a = ["future-", labe]
              let b = a.join("")
              const da = {
                instId: pair,
                tdMode: index,
                ccy: pair12,
                tag: "mk1",
                side: "sell",
                orderType: ordertype,
                sz: Amount,
                px: "0",
                trade_at: b,
                lever: value,
                market: { market }
              }
              const { data } = await Axios.post(`/trade/CreateTrade`, da, {
                headers: {
                  Authorization: localStorage.getItem("Mellifluous"),
                }
              })
              if (data) {
                setload(true)
                toast.success(data.message, {

                  duration: 4000,
                  position: "top-center",

                  // Styling
                  style: {
                    padding: "1rem",
                    fontSize: "15px",
                    color: "green",
                    fontWeight: "bold",
                  },
                  className: "",

                  // Custom Icon
                  icon: "👏",

                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },

                  // Aria
                  ariaProps: {
                    role: "status",
                    "aria-live": "polite",
                  },
                });
                setAmount("")
              } else {
                setload(true)
                toast.success(data?.message, {

                  duration: 4000,
                  position: "top-center",

                  // Styling
                  style: {
                    padding: "1rem",
                    fontSize: "15px",
                    color: "green",
                    fontWeight: "bold",
                  },
                  className: "",

                  // Custom Icon
                  icon: "👏",

                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },

                  // Aria
                  ariaProps: {
                    role: "status",
                    "aria-live": "polite",
                  },
                });
              }
            }

          }
        } else {
          // alert("else")
          // const { data } = await Axios.post(`/trade/positionHistory`, { id: pair }, {
          //   headers: {
          //     Authorization: localStorage.getItem("Mellifluous"),
          //   }
          // })
          // let his = data.result[0]
          if (user.trader_type === "user") {
            var a = ["future-", labe]
            var b = a.join("")
            // const da = {
            //   instId: his?.instId,
            //   tdMode: his?.mgnMode,
            //   ccy: his?.ccy,
            //   tag: "mk1",
            //   side: "buy",
            //   orderType: ordertype,
            //   sz: his?.notionalUsd,
            //   px: "0",
            //   trade_at: b,
            //   lever: value,
            //   market: market
            // }
            const da = {
              instId: pair,
              tdMode: index,
              ccy: pair,
              side: "buy",
              orderType: ordertype,
              sz: Amount,
              px: `${price}`,
              trade_at: b,
              lever: value,
              market: market
            }
            const { data } = await Axios.post(`/trade/userTrade`, da, {
              headers: {
                Authorization: localStorage.getItem("Mellifluous"),
              }
            })
            if (data.success) {
              setload(!false)
              toast.success(data.message, {

                duration: 4000,
                position: "top-center",

                // Styling
                style: {
                  padding: "1rem",
                  fontSize: "15px",
                  color: "green",
                  fontWeight: "bold",
                },
                className: "",

                // Custom Icon
                icon: "👏",

                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },

                // Aria
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              });
              setAmount("")
            } else {
              setload(!false)
              toast.error(data?.message, {

                duration: 4000,
                position: "top-center",

                // Styling
                style: {
                  padding: "1rem",
                  fontSize: "15px",
                  color: "red",
                  fontWeight: "bold",
                },
                className: "",

                // Custom Icon
                // icon: "👏",

                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "red",
                  secondary: "#fff",
                },

                // Aria
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              });
            }
          } else {

            var a = ["future-", labe]
            var b = a.join("")
            // const da = {
            //   instId: his?.instId,
            //   tdMode: his?.mgnMode,
            //   ccy: his?.ccy,
            //   tag: "mk1",
            //   side: "buy",
            //   orderType: ordertype,
            //   sz: his?.notionalUsd,
            //   px: "0",
            //   trade_at: b,
            //   lever: value
            // }
            const da = {
              instId: pair,
              tdMode: index,
              ccy: pair,
              tag: "mk1",
              side: "sell",
              orderType: ordertype,
              sz: Amount,
              px: "0",
              trade_at: b,
              lever: value
            }
            const { data } = await Axios.post(`/trade/CreateTrade`, da, {
              headers: {
                Authorization: localStorage.getItem("Mellifluous"),
              }
            })
            if (data) {
              setload(!false)
              toast.success(data.message, {

                duration: 4000,
                position: "top-center",

                // Styling
                style: {
                  padding: "1rem",
                  fontSize: "15px",
                  color: "green",
                  fontWeight: "bold",
                },
                className: "",

                // Custom Icon
                icon: "👏",

                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },

                // Aria
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              });
              setAmount("")
            } else {
              setload(!false)
              toast.error("Something Went Wrong", {

                duration: 4000,
                position: "top-center",

                // Styling
                style: {
                  padding: "1rem",
                  fontSize: "15px",
                  color: "red",
                  fontWeight: "bold",
                },
                className: "",

                // Custom Icon
                icon: "",

                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },

                // Aria
                ariaProps: {
                  role: "status",
                  "aria-live": "polite",
                },
              });
            }
          }
        }
      } else {
        setload(!false)
        toast.error("Please Select the Trade-Pair", {
          //  to
          duration: 4000,
          position: "top-center",

          // Styling
          style: {
            padding: "1rem",
            fontSize: "15px",
            color: "red",
            fontWeight: "bold",
          },
          className: "",

          // Custom Icon
          icon: "",

          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },

          // Aria
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      }
    } catch (error) {
      setload(!false)
      toast.error(error.response.data.message, {

        duration: 4000,
        position: "top-center",

        // Styling
        style: {
          padding: "1rem",
          fontSize: "15px",
          color: "red",
          fontWeight: "bold",
        },
        className: "",

        // Custom Icon
        icon: "",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  }

  // const getmyWallet = () => {
  //   try {
  //     Axios.get(`/wallet/getWalletById`, {
  //       headers: {
  //         Authorization: localStorage.getItem("Mellifluous"),
  //       },
  //     })
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           console.log(res?.data?.success, "dates")
  //           setBalance(res?.data?.result)
  //           console.log(res?.data?.result, "respon")
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log(error)
  //   }

  // }

  const getmyWallet = async () => {
    try {
      await Axios.get(`/bybit/getwallets`, {
        headers: {
          Authorization: localStorage.getItem("Mellifluous"),
        },
      })
        .then((res) => {
          if (res?.data?.success) {
            console.log(res, 'WALLET BALANCE', pair.substring(0, pair.length - 4));
            for (let i = 0; i < res?.data?.result.length; i++) {
              // if (res?.data?.result[i].symbol === pair.split("-")[1]) {
              //   setBalance(res?.data?.result[i].balance);
              // }
              if (res?.data?.result[i].coinname == pair?.slice(0, - 4)) {
                setBalance(res?.data?.result[i].balance);
                console.log(res?.data?.result[i].balance, "baln")
              }
            }
            // setBalance(res?.data?.result)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getmyWallet()

  }, [])

  return (
    <>
      <div className="Form-Inner-box form-Inner-box-style future-form-block-right-part">

        {/* <label className="form-label-style">Price ({selected ? selected?.pair.split('-')[1] : "USD"})</label> */}
        {/* <div className="price-limit-spot">
        <label className="form-label-style">Price 99({pair ? pair.split('-')[1] : "USD"})</label>
        <div className="">
          <TextField
            type={"text"}
            id="outlined-basic"
            variant="outlined"
            value="Market Price"
          />
        </div>
        </div> */}

        <div className="amount-limit-spot">
          <label className="form-label-style">Amount ({pair ? pair.split('-')[0] : "USD"})</label>
          <div className="">
            {labe === "open-short" ? <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                type={"number"}
                value={Amount}
                inputRef={Amountref}
                onChange={Amountupdate}
                placeholder={`Min ${pair ? pair.split('-')[0] : "USD"}`}
                // endAdornment={
                //   <InputAdornment position="end">
                //     Min  (<span>{pair ? pair.split('-')[0] : "USD"}</span>)
                //   </InputAdornment>
                // }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}

              />
            </FormControl>
              :
              <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  type={"text"}
                  value={`${sli}%`}
                  // inputRef={Amount}
                  // onChange={Amountupdate}
                  onChange={(e) => handleonChange(e)}
                  placeholder={`Min ${pair ? pair.split('-')[0] : "USD"}`}
                  // endAdornment={
                  //   <InputAdornment position="end">
                  //     Min  (<span>{pair ? pair.split('-')[0] : "USD"}</span>)
                  //   </InputAdornment>
                  // }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}

                />
              </FormControl>
            }

            {labe === "open-short" ?
              <ThumbSlider
                aria-label="Temperature"
                defaultValue={0}
                value={value}
                onChange={(e) => handleonChange(e)}
                valueLabelDisplay="auto"
                marks={true}
                step={10}
                min={0}
                max={20}
                ValueLabelComponent={ValueLabelComponent}
              />
              :
              <ThumbSlider
                aria-label="Temperature"
                defaultValue={0}
                value={value}
                onChange={(e) => handleonChange(e)}
                valueLabelDisplay="auto"
                marks={true}
                step={25}
                min={0}
                max={100}
                ValueLabelComponent={ValueLabelComponent}
              />}


          </div>
          <Stack className="percentage-button-row">
            <Button key={1} className={activeClass === "1" ? "active-select" : "non-active"} id={"1"} value="10" onClick={(value) => percentageValue(value)}>10%</Button>
            <Button key={2} className={activeClass === "2" ? "active-select" : "non-active"} id={"2"} value="15" onClick={(value) => percentageValue(value)}>15%</Button>
            <Button key={3} className={activeClass === "3" ? "active-select" : "non-active"} id={"3"} value="20" onClick={(value) => percentageValue(value)}>20%</Button>
          </Stack>
        </div>

        <div>
          {/* <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
          <OutlinedInput
            value={total}
            type={"number"}
            onChange={totalupdate}
            id="outlined-adornment-weight"
            endAdornment={<InputAdornment position="end">{pair ? pair.split('-')[1] : "USD"}</InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl> */}
          {/* <div className='available-max-buy'>
        <span>Available <span>-- USDC</span></span>  
        <span>Max buy <span>-- BTC</span></span>
        </div> */}
          {/* <FormGroup className='requirmnent'><FormControlLabel control={<Checkbox defaultChecked />} label="Take profit" /><FormControlLabel required control={<Checkbox />} label="Stop loss" /></FormGroup> */}

          <div className="available-max-block available-max-buy">
            <div className="available-max-block-left">
              <div>
                <label>Availabe</label>
                {balance &&
                  balance.find((item) => item.symbol === "USDT") &&
                  balance.find((item) => item.symbol === "USDT").balance !==
                  undefined && (
                    <div>
                      {balance.find((item) => item.symbol === "USDT").balance.toFixed(3)}{" "}
                      USDT
                    </div>
                  )}

              </div>
              <div>
                <label>Maxbuy</label>
                {pair &&
                  balance &&
                  balance.find((item) => item.symbol === pair.split("-")[0]) && (
                    <div>
                      {/* {balance.find((item) => item.symbol === pair.split("-")[0])
                          .balance}{" "} */}
                      0.000000 {pair.split("-")[0]}
                    </div>
                  )}
              </div>
            </div>
            <div className="available-max-block-right"><SwapHorizIcon /></div>
          </div>

          <div className="take-profit-stop-loss-block">
            <div><FormControlLabel control={<Checkbox checked={isCheckedTakeProfit} onChange={handleChangeTakeProfit} />} label="TP/SL" /></div>
            {/* <div><FormControlLabel control={<Checkbox checked={isCheckedStopLoss} onChange={handleChangeStopLoss} />} label="Stop loss" /></div> */}
          </div>

          {isCheckedTakeProfit && (
            <div className="take-profit-stop-loss-forms take-profit-form">


              <div className="form-design-tp-sl form-design-tp-trigger-price">
                <label>TP trigger price ({`${pair ? pair.split('-')[0] : "USD"}`})</label>
                <div>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ inputProps: { min: "0" } }}
                  />
                  <FormControl className="select-hours-outer">
                    <InputLabel id="demo-simple-select-label">Last</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={takeProfit}
                      onChange={handleTakeProfit}
                      label="Last"
                      className="select-hours-inner"
                    >
                      <MenuItem value={10}>1h</MenuItem>
                      <MenuItem value={20}>24h</MenuItem>
                      <MenuItem value={30}>7h</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="form-design-tp-sl form-design-tp-trigger-price">
                <label>SL trigger price ({`${pair ? pair.split('-')[0] : "USD"}`})</label>
                <div>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ inputProps: { min: "0" } }}
                  />
                  <FormControl className="select-hours-outer">
                    <InputLabel id="demo-simple-select-label">Last</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={takeProfit}
                      onChange={handleTakeProfit}
                      label="Last"
                      className="select-hours-inner"
                    >
                      <MenuItem value={10}>1h</MenuItem>
                      <MenuItem value={20}>24h</MenuItem>
                      <MenuItem value={30}>7h</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

            </div>
          )
          }

          {isCheckedStopLoss && (
            <div className="take-profit-stop-loss-forms stop-loss-form">


              <div className="form-design-tp-sl form-design-tp-trigger-price">
                <label>SL trigger price ({`${pair ? pair.split('-')[0] : "USD"}`})</label>
                <div>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ inputProps: { min: "0" } }}
                  />
                  <FormControl className="select-hours-outer">
                    <InputLabel id="demo-simple-select-label">Last</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={takeProfit}
                      onChange={handleTakeProfit}
                      label="Last"
                      className="select-hours-inner"
                    >
                      <MenuItem value={10}>1h</MenuItem>
                      <MenuItem value={20}>24h</MenuItem>
                      <MenuItem value={30}>7h</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="form-design-tp-sl form-design-tp-order-price">
                <label>SL order price (USDT)</label>
                <div>
                  <TextField
                    id="outlined-basic"
                    InputProps={{ inputProps: { min: "0" } }}
                    value="Market"
                  />
                  <Button>Market</Button>
                </div>
              </div>

            </div>
          )
          }


          <Button className="Sell-SOL Buy-SOL" variant="contained" onClick={buytrade} disabled={!load}>
            {/* Short {selected ? selected?.pair.split('-')[1] : ""} */}
            Short {selected?.pair ? selected?.pair.slice(-4) : ""}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SellForminnermarket;











