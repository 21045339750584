const consts = {
  //  MainUrl: "http://localhost:3000",
  Main: "Development",
  //  BackendUrl: "http://localhost:3500/imperialApi",
  // socketurl: 'http://localhost:3500',
  // bybitsocketurl: "wss://stream.bybit.com/v5/public/linear",           // for order book
  // spotsocketurl: "wss://stream.bybit.com/v5/public/spot",           // for order book (spot)

  route: "",

  // MainUrl: "http://34.93.139.4",
  // BackendUrl: "http://34.93.139.4/imperialApi",
  MainUrl: "https://app.imperialx.exchange",
  BackendUrl: "https://app.imperialx.exchange/imperialApi",
  // Main: "Production",
  socketurl: "https://app.imperialx.exchange",
  // bybitsocketurl: " ",
  bybitsocketurl: "wss://stream.bybit.com/v5/public/linear",       // for order book.
  spotsocketurl: "wss://stream.bybit.com/v5/public/spot",           // for order book (spot)


};

export default consts;
